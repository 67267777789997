<template>
  <div class="icon-container-icon-container" v-bind:class="rootClassName">
    <svg
      viewBox="0 0 1024 1024"
      data-role="accordion-icon-closed"
      class="icon-container-icon"
    >
      <path
        d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
      ></path></svg
    ><svg
      viewBox="0 0 1024 1024"
      data-role="accordion-icon-open"
      class="icon-container-icon2"
    >
      <path
        d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconContainer',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.icon-container-icon-container {
  position: relative;
}
.icon-container-icon {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.icon-container-icon2 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}




</style>
